import { DISTRIBUTION, FRESHNESS, SCHEMA, VOLUME } from './variables';

export const formatConfigurationData = (data, checkType) => {
    let temp = {
        table_name: data['table_name'],
        date_field: data['date_field'],
        frequency: null,
        query: data['query'],
        dataSourceValue: data['dataSourceValue'],
        metricValue: data['metricValue'],
        checkbox: data['checkbox'],
        run_day: data['run_day'],
        run_time: data['run_time'],
        latency: data['latency'],
        group_by_col: data['group_by_col'],
        agg_type: data['agg_type'],
        notify_to: data['notify_to'],
        selected_group: data['selected_group'],
        selected_user: data['selected_user'],
        share_to: data['share_to'],
        column_name: data['column_name'],
        minimum_threshold: data['minimum_threshold'],
        custom_query_availability: data['custom_query_availability'],
    };

    if (checkType === FRESHNESS) {
        temp = {
            ...temp,
            check_type: 'freshness',
            date_field: data['date_field'],
            query: data['query'],
            dataSourceValue: data['dataSourceValue'],
            metricValue: data['metricValue'],
            checkbox: data['checkbox'],
            frequency: data['frequency'],
            latency: data['latency'],
            run_day: Number(data['run_day']),
            run_time: data['run_time'],
        };
    }
    if (checkType === SCHEMA) {
        temp = {
            ...temp,
            check_type: 'schema',
        };
    }

    if (checkType === VOLUME) {
        temp = {
            ...temp,
            check_type: 'volume',
            group_by_col: data['group_by_col'] || null,
            additional_threshold: data['additional_threshold'] || 0,
        };
    } else if (checkType === DISTRIBUTION) {
        temp = {
            ...temp,
            check_type: 'distribution',
            additional_threshold: data['additional_threshold'] || 0,
        };
    }

    return temp;
};

export const formatShareTo = (data) => {
    const { share_to } = data;
    if (share_to === undefined) {
        data.share_to = ['Everyone'];
    } else if (share_to.includes('Everyone')) {
        data.selected_group = '';
        data.selected_user = '';
        data.share_to = ['Everyone'];
    }
    return data;
};
