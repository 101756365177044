import React, { useState } from 'react';
import api from '../../api';
import 'react-widgets/scss/styles.scss';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Controller, useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function CreateGroup({ setIsOpen, isOpen }) {
    const [isloadingbutton, setIsloadingbutton] = useState(false);
    const authState = useSelector((state) => state.authUser);
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            group_name: '',
            description: '',
            created_by: authState?.profile?.user_id

        },
    });

    const handleOnSubmit = async (data) => {
        try {
            setIsloadingbutton(true);
            const request = { resource: 'api/groups' };
            const res = await api.grouplist.addGroup(request, {
                ...data,
            });
            if (res.data.status === 200) {
                toastr.success('Success', 'Group added successfully');
                setIsloadingbutton(false);
                navigate('/groups');
            } else {
                toastr.error('Error', res.data.message);
                setIsloadingbutton(false);
            }
        } catch (error) {
            toastr.error('Error', error.message);
            setIsloadingbutton(false);
        }
    };

    return (
        <Container>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
            >
                <div
                    className="rounded-5 mx-2 my-4 p-4 w-75 position-relative"
                    style={{
                        boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                        backgroundColor: 'whitesmoke',
                        marginLeft: '12.5%',
                    }}
                >
                    <h4 className="fw-bold ms-4 text-center">Add Group</h4>
                    <>
                        <Form
                            onSubmit={handleSubmit((data) =>
                                handleOnSubmit(data),
                            )}
                        >
                            <div className="col-12">
                                <div className="container mb-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="font-weight-semibold mb-2">
                                                Group Name
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </div>

                                            <Controller
                                                control={control}
                                                name="group_name"
                                                rules={{
                                                    required: true,
                                                }}
                                                defaultValue={''}
                                                render={({
                                                    field: { value, name },
                                                }) => (
                                                    <>
                                                        <input
                                                            value={value}
                                                            name={name}
                                                            onChange={(e) =>
                                                                setValue(
                                                                    'group_name',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className="form-control mt-1"
                                                            id="grid-group-name"
                                                            type="text"
                                                            aria-label="gname"
                                                        />
                                                    </>
                                                )}
                                            ></Controller>
                                            {errors.group_name && (
                                                <div className="text-danger  text-start  mx-0 p-2">
                                                    {errors.group_name?.type ===
                                                    'required'
                                                        ? 'Group name is required'
                                                        : ''}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6">
                                            <div className="font-weight-semibold mb-2">
                                                Description
                                            </div>

                                            <Controller
                                                control={control}
                                                name="description"
                                                defaultValue={''}
                                                render={({
                                                    field: { value, name },
                                                }) => (
                                                    <>
                                                        <input
                                                            value={value}
                                                            name={name}
                                                            onChange={(e) =>
                                                                setValue(
                                                                    'description',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className="form-control mt-1"
                                                            id="grid-description"
                                                            type="text"
                                                            aria-label="description"
                                                        />
                                                    </>
                                                )}
                                            ></Controller>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="button"
                                        onClick={() => navigate('/groups')}
                                        className="btn btn my-3  mt-4 mx-3 "
                                        variant="outline-secondary"
                                        size="md"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={isloadingbutton}
                                        type="submit"
                                        variant="success"
                                        className="btn btn my-3   mt-4 px-3"
                                        style={{
                                            backgroundColor: 'rgb(73, 151, 73)',
                                        }}
                                    >
                                        {isloadingbutton ? (
                                            <div className="d-flex flex-row gap-2">
                                                <Spinner
                                                    className="text-dark  mx-2"
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        ) : (
                                            <>{'Save'}</>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </>
                </div>
            </div>
        </Container>
    );
}
