import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import './logs.scss';

export default function ReportLogsTable({ columns, row }) {
    // Call hooks unconditionally
    const memoizedColumns = useMemo(() => columns || [], [columns]);
    const memoizedRow = useMemo(() => row || [], [row]);

    const tableInstance = useTable({
        columns: memoizedColumns,
        data: memoizedRow,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    // Conditional render check moved here after hook execution
    if (!columns?.length || !row?.length) {
        return (
            <div
                className="card-heading"
                style={{
                    width: '100%',
                    minHeight: '200px',
                    height: '300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #ccc',
                }}
            >
                No Data Available
            </div>
        )
    }

    return (
        <Table
            {...getTableProps()}
            responsive
            hover
            className='reportLogTable'
            style={{
                borderSpacing: '0  15px',
                backgroundColor: '#fff',
                fontSize: '17px',
            }}
        >
            <thead style={{ backgroundColor: '#F5F5F5' }}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="py-3 fw-bold"
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="border">
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className="py-2 "
                                        style={{
                                            fontSize: '13px',
                                            lineHeight: '16px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
