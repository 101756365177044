import { useState, useEffect, useCallback } from 'react';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Multiselect } from 'react-widgets';
import { Button, Spinner } from 'react-bootstrap';
// import "react-widgets/styles.css";

const GroupUsers = ({ show, onClose, id }) => {
    const [loading, setLoading] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [data, setData] = useState({ users: [], group: {}, nonUsers: [] });
    const [addUsers, setAddUsers] = useState([]);
    const [removeUsers, setRemoveUsers] = useState([]);

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.user.getUsersByGroupId(id);
            if (response.data.status === 200) {
                setData({
                    users: response.data.data.user,
                    group: response.data.data.groups,
                    nonUsers: response.data.data.nonUser,
                });
            } else {
                toastr.error('Error', 'Failed to fetch users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setLoading(false), 1500);
    }, [id]);

    useEffect(() => {
        if (show) {
            fetchUsers();
            setAddUsers([]);
            setRemoveUsers([]);
            // const timer = setTimeout(() => setLoading(false), 1500);
            // return () => clearTimeout(timer);
        }
    }, [show, fetchUsers]);

    if (!show) return null;

    // const CustomItem = ({ item }) => (
    //     <span>{item.display_name} ({item.email})</span>
    // );

    const adminUsers = data.users.filter(
        (user) => user['role.role_name'] === 'Admin',
    );
    const nonAdminUsers = data.users.filter(
        (user) => user['role.role_name'] !== 'Admin',
    );

    const availableUsers = data.nonUsers || [];

    // const handleAddUser = () => {
    //     if(removeMultiselect) setRemoveMultiselect(false);
    //     setAddMultiselect(prevState => !prevState);
    // };

    // const handleRemoveUser = ()=>{
    //     if(addMultiselect===true) setAddMultiselect(false);
    //     setRemoveMultiselect(prevState => !prevState)
    // }

    const handleAddUserSelect = (selected) => {
        setAddUsers(selected);
    };

    const handleRemoveUserSelect = (selected) => {
        setRemoveUsers(selected);
    };

    const handleCancel = () => {
        setAddUsers([]);
        setRemoveUsers([]);
        setActiveSection(null);
    };

    const handleAddSubmit = async () => {
        if (addUsers.length === 0) {
            alert('Please select at least one user.');
            return;
        }
        setIsAdding(true);
        const requestData = {
            groupId: data.group.group_id,
            userIds: addUsers.map((user) => user.user_id),
        };

        try {
            const response = await api.user.addUsersToGroup(requestData);
            if (response.data.status === 200) {
                toastr.success('Success', `${response.data.message}`);
                fetchUsers();
            } else {
                toastr.error('Error', 'Failed to Add Users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setIsAdding(false), 1500);
        handleCancel();
    };

    const handleRemoveSubmit = async () => {
        if (removeUsers.length === 0) {
            alert('Please select at least one user.');
            return;
        }
        setIsRemoving(true);
        const requestData = {
            groupId: data.group.group_id,
            userIds: removeUsers.map((user) => user.user_id),
        };

        try {
            const response = await api.user.deleteUsersFromGroup(requestData);
            if (response.data.status === 200) {
                toastr.success('Success', `${response.data.message}`);
                fetchUsers();
            } else {
                toastr.error('Error', 'Failed to Remove Users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setIsRemoving(false), 1500);
        handleCancel();
    };

    return (
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex justify-content-center align-items-center">
            <div
                className="bg-white p-4 rounded shadow-lg"
                style={{
                    width: '80%',
                    maxHeight: '100vh',
                    overflow: 'hidden',
                }}
            >
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">Group Users</h4>
                    <button
                        className="btn-close"
                        onClick={() => {
                            handleCancel();
                            onClose();
                        }}
                    ></button>
                </div>

                <div className="row border p-3 rounded mb-3 bg-light align-items-center">
                    <div className="d-flex justify-content-around col-9">
                        <div className="d-flex flex-column me-4">
                            <strong>Name:</strong>
                            <span>{data.group.group_name}</span>
                        </div>
                        <div className="d-flex flex-column me-4">
                            <strong>Description:</strong>
                            <span>{data.group.description}</span>
                        </div>
                        <div className="d-flex flex-column me-4">
                            <strong>Created By:</strong>
                            <span>{data.group.created_by}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <strong>Status:</strong>
                            <span
                                className={`badge ${
                                    data.group.is_active
                                        ? 'bg-success'
                                        : 'bg-danger'
                                }`}
                            >
                                {data.group.is_active ? 'Active' : 'Inactive'}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center btn-group col-3">
                        <button
                            className="btn btn-primary me-2"
                            onClick={() => {
                                setActiveSection(
                                    activeSection === 'add' ? null : 'add',
                                );
                            }}
                        >
                            Add User
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                setActiveSection(
                                    activeSection === 'remove'
                                        ? null
                                        : 'remove',
                                );
                            }}
                        >
                            Remove User
                        </button>
                    </div>
                </div>
                {activeSection && (
                    <div className="mb-3">
                        <label>
                            <strong>
                                {activeSection === 'add'
                                    ? 'Add Users'
                                    : 'Remove Users'}
                                :
                            </strong>
                        </label>
                        <Multiselect
                            data={
                                activeSection === 'add'
                                    ? availableUsers
                                    : nonAdminUsers
                            }
                            value={
                                activeSection === 'add' ? addUsers : removeUsers
                            }
                            textField={'display_name'}
                            onChange={
                                activeSection === 'add'
                                    ? handleAddUserSelect
                                    : handleRemoveUserSelect
                            }
                            placeholder="Search by name or email..."
                            filter={(user, searchTerm) =>
                                user.display_name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase()) ||
                                user.email
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                            }
                        />
                        <div className="mt-2">
                            <Button
                                variant={
                                    activeSection === 'add'
                                        ? 'success'
                                        : 'danger'
                                }
                                onClick={
                                    activeSection === 'add'
                                        ? handleAddSubmit
                                        : handleRemoveSubmit
                                }
                                disabled={
                                    (activeSection === 'add'
                                        ? isAdding
                                        : isRemoving) ||
                                    (activeSection === 'add'
                                        ? addUsers.length === 0
                                        : removeUsers.length === 0)
                                }
                                style={{ width: '100px' }}
                            >
                                {(
                                    activeSection === 'add'
                                        ? isAdding
                                        : isRemoving
                                ) ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                    />
                                ) : (
                                    'Confirm'
                                )}
                            </Button>
                            <Button
                                className="ms-2"
                                variant="secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}

                <div
                    style={{
                        maxHeight: '70vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: '100px' }}
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <div className="row">
                            <div
                                className="col-md-6"
                                style={{ maxHeight: '50vh', overflowY: 'auto' }}
                            >
                                <h5 className="text-primary text-center">
                                    Admins
                                </h5>
                                {adminUsers.length > 0 ? (
                                    <ul className="list-group mb-3">
                                        {adminUsers.map((user, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                <div>
                                                    <strong>
                                                        {user.display_name}
                                                    </strong>{' '}
                                                    <br />
                                                    <small>{user.email}</small>
                                                </div>
                                                <span
                                                    className={`badge ${
                                                        user.is_active
                                                            ? 'bg-success'
                                                            : 'bg-danger'
                                                    }`}
                                                >
                                                    {user.is_active
                                                        ? 'Active'
                                                        : 'Inactive'}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-muted text-center">
                                        No Admin users found.
                                    </p>
                                )}
                            </div>

                            <div
                                className="col-md-6"
                                style={{ maxHeight: '50vh', overflowY: 'auto' }}
                            >
                                <h5 className="text-secondary text-center">
                                    Non-Admins
                                </h5>
                                {nonAdminUsers.length > 0 ? (
                                    <ul className="list-group">
                                        {nonAdminUsers.map((user, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                <div>
                                                    <strong>
                                                        {user.display_name}
                                                    </strong>{' '}
                                                    <br />
                                                    <small>{user.email}</small>
                                                </div>
                                                <span
                                                    className={`badge ${
                                                        user.is_active
                                                            ? 'bg-success'
                                                            : 'bg-danger'
                                                    }`}
                                                >
                                                    {user.is_active
                                                        ? 'Active'
                                                        : 'Inactive'}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-muted text-center">
                                        No Non-Admin users found.
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroupUsers;
