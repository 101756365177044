import React, { useMemo, useState } from 'react';
import { Accordion, Table, Button, Row, Col } from 'react-bootstrap';
import { useTable } from 'react-table';
import './user.scss';
import Card from 'react-bootstrap/Card';
import { FaCircle, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import api from '../../../api';
import { toastr } from 'react-redux-toastr';
import ModalPage from '../../modal/modal';
export default function UserTable({ isUserDeleted, setIsUserDeleted, columns, row }) {
    const memoziedColumns = useMemo(() => columns, [columns]);
    const memoziedRow = useMemo(() => row, [row]);
    const [arrow, setArrow] = useState(-1);
    const [storeUserId, setStoreUserId] = useState();
    const [userDelete, setUserDelete] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            arrow === eventKey ? setArrow(-1) : setArrow(eventKey);
        });

        return (
            <>
                <Button
                    className=" bg-light text-dark border-0 p-2 m-0 btn-acc"
                    type="button"
                    onClick={decoratedOnClick}
                >
                    {children}
                </Button>
            </>
        );
    }
    const handleEdit = (row) => {
        navigate(`/edituser/${row.original.user_id}`);
    };
    const tableInstance = useTable({
        columns: memoziedColumns,
        data: memoziedRow,
    });
    // function to delete the user by user id in the user table on clicking the delete icon
    const handleDelete = async(id) =>{
        try {
            const res = await api.user.deleteUser(id);
            if(res?.data && res?.data?.status === 200){
                toastr.success('Success', 'User deleted successfully.');
                setIsUserDeleted(!isUserDeleted)
            } else {
                toastr.error('Error', res?.data?.message)
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;
    return (
        <>
        <Table
            {...getTableProps()}
            responsive
            hover
            style={{
                // borderCollapse: 'separate',
                borderSpacing: '0  15px',
                backgroundColor: '#fff',
                fontSize: '17px',
            }}
        >
            <thead style={{ backgroundColor: '#F5F5F5' }}>
                <Row>
                    <Col sm={5} className="px-5 fw-bold">
                        User
                    </Col>
                    <Col sm={3} className="fw-bold">
                        First Name
                    </Col>
                    <Col sm={2} className=" fw-bold">
                        Last Name
                    </Col>
                    <Col sm={1} className="px-2 fw-bold">
                        Action
                    </Col>
                    <Col sm={1} className="px-2 fw-bold">
                        Delete
                    </Col>
                </Row>
            </thead>
            <Accordion>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} className="table-main">
                            <Row>
                                <Col sm={5}>
                                    <CustomToggle eventKey={i}>
                                        <>
                                            {arrow === i ? (
                                                <FaAngleDown></FaAngleDown>
                                            ) : (
                                                <FaAngleRight></FaAngleRight>
                                            )}
                                        </>
                                    </CustomToggle>

                                    <div className="d-flex flex-row gap-1  color ">
                                        <div
                                            style={{
                                                width: '38px',
                                                backgroundColor: '#1F67C6',
                                                height: '37px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <h6 className="text-light  text-uppercase d-flex flex-row pt-1">
                                                <div>
                                                    {
                                                        row.original.first_name
                                                            .split('')
                                                            .join()[0]
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        row.original.last_name
                                                            .split('')
                                                            .join()[0]
                                                    }
                                                </div>
                                            </h6>
                                        </div>
                                        <div className="pt-2">
                                            <div
                                                className="fw-bolder"
                                                style={{ fontSize: '15px' }}
                                            >
                                                {row.original.display_name}
                                            </div>
                                            <div
                                                className="text-secondary"
                                                style={{ fontSize: '15px' }}
                                            >
                                                {row.original.email}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            paddingTop: 5,
                                        }}
                                    >
                                        {row.original.first_name}
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            paddingTop: 5,
                                        }}
                                    >
                                        {row.original.last_name}
                                    </div>
                                </Col>
                                <Col sm={1}>
                                    <FaEdit
                                        className="text-dark mx-2 font-size lastelement actions-styles"
                                        onClick={() => {
                                            handleEdit(row);
                                        }}
                                    ></FaEdit>
                                </Col>
                                <Col sm={1}>
                                    <FaTrashAlt
                                        className="text-dark mx-2 font-size lastelement actions-styles"
                                        onClick={() => {
                                            setUserDelete(true);
                                            setModalShow(true);
                                            setStoreUserId(row.original.user_id);
                                        }}
                                    ></FaTrashAlt>
                                </Col>
                            </Row>
                            <Row>
                                <Accordion.Collapse eventKey={i}>
                                    <Row>
                                        <Col sm={5}>
                                            <div className="d-flex flex-row gap-1  color "></div>
                                        </Col>
                                        <Col sm={3}>
                                            <Row>
                                                <th classNameName="py-3 fw-bold">
                                                    Group
                                                </th>
                                            </Row>
                                            <Row>
                                                <div
                                                    style={{
                                                        fontSize: '16px',
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {row?.original?.user_groups
                                                        ?.filter(
                                                            (item) =>
                                                                item.is_active ===
                                                                true,
                                                        )
                                                        .map((item) => {
                                                            return (
                                                                <div className="display-linebreak">
                                                                    {
                                                                        item
                                                                            .group
                                                                            .group_name
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col sm={2}>
                                            <Row>
                                                <th classNameName="py-3 fw-bold">
                                                    Role
                                                </th>
                                            </Row>
                                            <Row>
                                                <div
                                                    style={{
                                                        fontSize: '16px',
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {
                                                        row.original.role
                                                            .role_name
                                                    }
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col sm={2}>
                                            <Row>
                                                <th classNameName="py-3 fw-bold lastelement">
                                                    Status
                                                </th>
                                            </Row>
                                            <Row>
                                                <div
                                                    style={{
                                                        fontSize: '16px',
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    {row.original.is_active ? (
                                                        <div className="d-flex flexx-row gap-1">
                                                            <FaCircle className="pt-2 text-success"></FaCircle>
                                                            <div>Active</div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flexx-row gap-1">
                                                            <FaCircle className="pt-2 text-danger"></FaCircle>
                                                            <div>Inactive</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Accordion.Collapse>
                            </Row>
                        </div>
                    );
                 })}
            </Accordion>
        </Table>
        {userDelete && (
            <ModalPage
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading={'Delete User'}
                body={'Are you sure want to delete ?'}
                success={'Delete'}
                failure={'Cancel'}
                id={storeUserId}
                onClose={true}
                action={handleDelete}
            />
        )}
        </>
    );
}
