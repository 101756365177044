export const RouteKeys = {
    Login: '/auth/login',
    Home: '/',
    NotFound: '/page-not-found',
    Metrics: 'metrics',
    SourceList: 'sourceList',
    Auth: '/auth',
    AzureLogin: '/auth/aad-redirect',
    AddUser: '/users',
    Themes: 'themes',
    AddMetrics: '/addMetrics',
    EditMetrics: '/editMetrics/:id',
    CreateUser: '/addUser',
    EditUser: '/edituser/:id',
    AddVariable: '/addSource',
    Dashboard: '/dashboard/:checktype',
    Volume: 'anamoly/:id',
    Volume1: '/dashboard/volume1',
    AddConfigurationSettings: '/addConfigurationSettings',
    DataSource: '/dataSource',
    ReportLogs: '/report-logs',
    SchemaAnamoly: '/anamoly/schema/:id/:anamoly_id',
    Schema1: '/dashboard/schema1',
    DistributionAnamoly: 'anamoly/distribution/:id',
    ViewDataSource: 'datasource/:secret',
    Qlik: '/qlik/:appId/:sheetId',
    Freshness: '/reports/freshness',
    Freshness1: '/dashboard/freshness1',
    Pipeline: '/dashboard/pipeline',
    Analysis: '/reports/analysis',
    Distribution: '/reports/distribution',
    DistributionDashboard: '/dashboard/distribution1',
    Groups: '/groups',
    CreateGroup: '/addGroup',
    EditGroup: '/editgroup/:id',
};
