import React, { useState, useEffect } from 'react';
import './ReportLogs.scss';
import axios from 'axios';
import Filter from '../../components/filter/filter-logs';
import api from '../../api';
import moment from 'moment';
import 'moment-timezone';
import DynamicReportLogsTable from '../../components/tablewithpaginationandlimit/report-logs-table';
// import Filter from '../../components/filter/filter';
import Filters from '../../components/filter/filters';
import { Container, Row, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { IoPersonAddSharp } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import SearchBar from '../../components/searchBar/searchBar';
import { useNavigate } from 'react-router-dom';
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';
import CreateUser from '../../components/Crud/CreateUser';
import Sort from '../../components/sort/sort';
import Limitdropup from '../../components/limit-drop-up/limit-drop-up';
import Pagination from '../../components/pagination/pagination';

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [filter, setFilter] = useState();
    const [sort, setSort] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [filterData, setFilterData] = useState([
        'STARTED',
        'COMPLETED',
        'ALREADY RUNNING',
    ]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emptyFilter, setEmptyFilter] = useState(false);
    const [emptySearchbar, setEmptySearchbar] = useState(false);

    const navigate = useNavigate();

    const request = {
        resource: 'api/report_logs',
        limit,
        currentPage,
        search,
        sort,
        filter,
    };

    // for limit
    const handlingLimit = (limit) => {
        setLimit(limit);
        setCurrentPage(1);
    };

    //for page change
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    //for sort
    const handleSort = (value) => {
        setSort(value);
        setCurrentPage(1);
    };

    //for search
    const handleSearch = (value) => {
        setEmptyFilter(!emptyFilter);
        setFilter({
            dataSource: [],
            checktypes: [],
        });
        setSearch(value);
        setCurrentPage(1);
    };

    //for filter
    const handleFilter = (value) => {
        setEmptySearchbar(!emptySearchbar);
        setSearch('');
        setFilter(value);
        setCurrentPage(1);
    };

    function getRandomColor() {
        let color = '#';
        const letters = '0123456789ABCDEF';

        for (let i = 0; i < 6; i++) {
            const index = Math.floor(Math.random() * letters.length);
            color += letters[index];
        }

        return color;
    }
    const handleEdit = (row) => {
        navigate(`/edituser/${row.original.user_id}`);
    };

    const convertPSTTimeFormat = (timeStamp) => {
        const timestampWithoutZ = timeStamp?.slice(0, -1);
        let momentPST = moment
            .tz(timestampWithoutZ, 'Asia/Kolkata')
            .tz('America/Los_Angeles');
        var formattedPST = momentPST.format('hh:mm A');
        return formattedPST;
    };

    const convertPSTDateFormat = (timeStamp) => {
        const timestampWithoutZ = timeStamp?.slice(0, -1);
        let momentPST = moment
            .tz(timestampWithoutZ, 'Asia/Kolkata')
            .tz('America/Los_Angeles');
        var formattedPST = momentPST.format('MM/DD/YYYY');
        return formattedPST;
    };

    const columns = [
        {
            Header: 'Check Type',
            accessor: 'pillar_name',
        },
        {
            Header: 'Job Name',
            accessor: 'dag_name',
        },
        {
            Header: 'Run Date',
            accessor: 'updatedAt',
            Cell: ({ value }) => <>{convertPSTDateFormat(value)}</>,
        },
        {
            Header: 'Run Time',
            accessor: 'createdAt',
            Cell: ({ value }) => <>{convertPSTTimeFormat(value)}</>,
            // Cell: (createdAt) => (
            //     <>{moment(createdAt).utc().format('hh:mm A')}</>
            // ),
        },
        {
            Header: 'Run Status',
            accessor: 'status',
        },
    ];

    const fetchReportLogsTable = async () => {
        try {
            setIsLoading(true);
            const logsData = await api.crud.getList(request);
            setPages(10);
            setLogs(logsData.data.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchReportLogsTable();
    }, [limit, currentPage, sort, search, filter]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container>
            <>
                <div className="d-flex justify-content-between">
                    <h5 className="text-dark pt-4 fw-bold">Report Logs</h5>
                </div>
                <Row>
                    <Col md={4} sm={12} xs={12}>
                        {/* <SearchBar
                            handleSearch={handleSearch}
                            emptySearchbar={emptySearchbar}
                        ></SearchBar> */}
                    </Col>
                    <Col
                        md={7}
                        sm={6}
                        xs={6}
                        className="m-0"
                        style={{ width: '57%' }}
                    >
                        <Filter
                            filterData={filterData}
                            handleFilter={handleFilter}
                            emptyFilter={emptyFilter}
                            data={[
                                'Volume',
                                'Distribution',
                                'Schema',
                                'Freshness',
                            ]}
                        ></Filter>
                    </Col>
                    <Col md={1} sm={4} xs={4} className="m-0 p-0">
                        <Row>
                            <Col md={6}>
                                <Sort
                                    handleSort={handleSort}
                                    data={[
                                        {
                                            name: 'Check type',
                                            column_name: 'pillar_name',
                                        },
                                        // {
                                        //     name: 'Source',
                                        //     column_name: 'table_name',
                                        // },
                                        {
                                            name: 'Job Name',
                                            column_name: 'dag_name',
                                        },
                                        {
                                            name: 'Run Date',
                                            column_name: 'created_at',
                                        },
                                        // {
                                        //     name: 'Run Time',
                                        //     column_name: 'createdAt',
                                        // },
                                    ]}
                                ></Sort>
                            </Col>
                            {/* <Col md={6}>
                                {bulkDelete.length !== 0 && (
                                    <Button
                                        variant="danger"
                                        className={`border border-light notification `}
                                        onClick={() => {
                                            setModalShow(true);
                                            setIsBulk(true);
                                            setIsSingle(false);
                                        }}
                                    >
                                        <FaTrashAlt></FaTrashAlt>
                                        <span className="badge  border border-primary ">
                                            {bulkDelete.length}
                                        </span>
                                    </Button>
                                )}
                            </Col> */}
                        </Row>
                    </Col>
                </Row>

                {isLoading ? (
                    <TableSkeletonLoader
                        intialrows={8}
                        initalcolumns={5}
                    ></TableSkeletonLoader>
                ) : (
                    <>
                        <DynamicReportLogsTable
                            row={logs}
                            columns={columns}
                            isPagination={false}
                            isLimit={false}
                            pages={pages}
                            limit={limit}
                            handlingLimit={handlingLimit}
                            handlePageChange={handlePageChange}
                            currentPage={currentPage}
                        ></DynamicReportLogsTable>
                        <div className="d-flex justify-content-end  mx-auto  w-100 pb-5">
                            <Row>
                                <div className="d-flex  mx-3">
                                    <div className="py-2 font-weight-semibold">
                                        Records per page
                                    </div>
                                    <div className=" px-1">
                                        <Limitdropup
                                            limit={limit}
                                            handlingLimit={handlingLimit}
                                        ></Limitdropup>
                                    </div>
                                </div>
                            </Row>

                            <div className="py-1">
                                <Pagination
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    pages={pages}
                                ></Pagination>
                            </div>
                        </div>
                    </>
                )}
            </>
        </Container>
    );
};

export default Logs;
